import React, { useContext, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  Article,
  Ballot,
  Construction,
  Description,
  Settings,
} from '@mui/icons-material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { useDialog, useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import CustomTabs from './Tabs';
import moment from 'moment';

const Ordem = () => {
  const { onChangeTab, tab } = useOutletContext();
  const { id } = useParams();
  const { closeDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const {
    ordem,
    getLoading,
    getOrdem,
    postDocumento,
    postLoading,
    postAnexo,
    deleteAnexo,
  } = useContext(OrdensContext);
  const disableActions =
    Boolean(ordem?.DocumentoHists?.length) &&
    ordem?.DocumentoHists?.find((f) => Boolean(f?.status_id))?.Statusdc
      ?.editar === 'NAO';

  const loadDocumento = () => getOrdem(id);

  useEffect(() => {
    loadDocumento();
    onChangeTab({ tab: tab, key: id });
  }, []);

  const onSubmit = (data) =>
    postDocumento({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onSubmitAnexo = (data) => {
    postAnexo({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });
  };

  const onDeleteAnexo = (id) => {
    deleteAnexo({
      id,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });
  };

  const onDelete = (data) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            ...data,
            cb: () => {
              loadDocumento();
              closeModal();
            },
          })
        }
      />
    );

  const tabs = [
    {
      value: 0,
      key: 'Documento',
      label: 'Documento',
      icon: <Description />,
    },
    {
      value: 1,
      key: 'Serviço',
      label: 'Serviço',
      icon: <Settings />,
    },
    {
      value: 2,
      key: 'Equipamentos',
      label: 'Equipamentos',
      icon: <Construction />,
    },
    {
      value: 3,
      key: 'Itens',
      label: 'Itens',
      icon: <Ballot />,
    },
    {
      value: 4,
      key: 'Registros',
      label: 'Registros',
      icon: <Article />,
    },
  ];

  return (
    <Container>
      <Header titulo={`Ordem de Serviço: ${ordem?.documento || ''}`} />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, tab) => onChangeTab({ tab, key: id })}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        drops={drops}
        documento={ordem}
        getLoading={getLoading}
        disableActions={disableActions}
        loading={postLoading}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onSubmitAnexo={onSubmitAnexo}
        onDeleteAnexo={onDeleteAnexo}
      />
    </Container>
  );
};

export default Ordem;
